/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { startLoading, stopLoading } from '../../../store/modules/loading';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';

export const useEditAssignationActivity = () => {
	const dispatch = useDispatch();

	const { execute: updateAssignments, status: statusUpdateAssignments } = useApiRequest<
		any,
		IResponsePayload<IStepTwoModel>
	>({
		path: URLS.EDIT_PROJECT.PERSONALS.ASSIGNMENTS,
		method: 'POST',
	});

	const executeUpdate = async (activity: IStepTwoModel): Promise<boolean> => {
		dispatch(startLoading());
		const isValid = await updateAssignments({ activities: [activity] });
		return isValid === true;
	};

	useEffect(() => {
		if (statusUpdateAssignments === 'success') {
			dispatch(stopLoading());
		} else if (statusUpdateAssignments === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar los responsables',
					severity: 'error',
				})
			);
		}
	}, [statusUpdateAssignments]);

	return { executeUpdate };
};
