import { SxProps, Theme } from '@mui/material/styles';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { IOptionsMenu } from '../interfaces/select.interface';
import stylesSelect from './styles/select-styles.component.module.css';

interface ITextArea {
	value: string | number;
	id?: string;
	onChange: (e: SelectChangeEvent<string | number | null>) => void;
	RenderTitle?: () => JSX.Element | null;
	optionsMenu: IOptionsMenu[];
	styles?: React.CSSProperties;
	formSx?: SxProps<Theme>;
	rootSx?: React.CSSProperties;
	selectSx?: SxProps<Theme>;
	containerSx?: React.CSSProperties;
	menuItemSx?: SxProps<Theme>;
	placeholder: string;
	disabled?: boolean;
	renderStart?: (value: any) => React.ReactNode;
	renderEnd?: (value: any) => React.ReactNode;
	filterOptions?: boolean;
}

const SelectComponent = (props: ITextArea) => {
	const {
		value,
		onChange,
		RenderTitle,
		styles,
		formSx,
		rootSx,
		menuItemSx,
		id,
		containerSx,
		optionsMenu,
		placeholder,
		disabled = false,
		renderStart,
		renderEnd,
		filterOptions,
	} = props;

	let filteredOptions = filterOptions
		? optionsMenu.filter((e) => e.active || e.isTeam)
		: optionsMenu;

	const selectedOption = optionsMenu.find((e) => e.value === value);
	if (selectedOption && !filteredOptions.some((e) => e.value === value)) {
		filteredOptions = [selectedOption, ...filteredOptions];
	}

	return (
		<div style={{ width: '100%', ...rootSx }}>
			{RenderTitle && <RenderTitle />}
			<div style={{ display: 'flex', ...containerSx }}>
				<FormControl variant="filled" sx={{ minWidth: 120, ...formSx }}>
					<Select
						id={id}
						value={value}
						size="small"
						onChange={onChange}
						style={styles}
						fullWidth
						disabled={disabled}
						displayEmpty
						renderValue={value !== '' ? undefined : () => placeholder}
						inputProps={{ id: `input-${id}` }}
					>
						{filteredOptions.map((e, idx) => (
							<MenuItem
								id={`${id}-${idx}`}
								sx={menuItemSx}
								key={e.id}
								value={e.value}
							>
								<div className={stylesSelect.selectContainer}>
									{renderStart && (
										<span
											className={stylesSelect.statusSelectContainer}
										>
											{renderStart(e.value)}
										</span>
									)}
									<span
										className={`${
											renderStart && renderEnd
												? stylesSelect.labelSelectContainerWithDecorations
												: stylesSelect.labelSelectContainerFullWidth
										}`}
									>
										{e.label}
									</span>
									{renderEnd && (
										<span
											className={stylesSelect.statusSelectContainer}
										>
											{renderEnd(e.value)}
										</span>
									)}
								</div>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		</div>
	);
};

export default SelectComponent;
