/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../../config/theme';

export const useEditAssignments = () => {
	const navigate = useNavigate();

	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return {
		matches,
		navigate,
	};
};
