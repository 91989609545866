/* eslint-disable react-hooks/exhaustive-deps */

import theme from '../../../../config/theme';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { INewInfoProject } from '../interfaces/new-info-project.interface';
import { useGetActivities } from './useGetActivities';
import { useEffect, useState } from 'react';
import { useGetPersonalByProject } from '../../../../hooks/usePersonalByProject';

interface IUseViewAssignments {
	currentProject: string;
	projectData: INewInfoProject | undefined;
}

export const useViewAssignments = ({
	currentProject,
	projectData,
}: IUseViewAssignments) => {
	const location = useLocation();
	const idUser = location.state.idUser as string;
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [newProjectData] = useState<INewInfoProject | undefined>(projectData);

	const { activitiesOptions, handleGetActivities, setActivitiesOptions } =
		useGetActivities({
			projectData: newProjectData,
		});
	const { usersData, getTotalUsers, getIsTeam } = useGetPersonalByProject({
		idProjectProp: currentProject,
		addTeams: true,
	});

	useEffect(() => {
		handleGetActivities(idUser, currentProject);
		getTotalUsers();
	}, []);

	const handleActivity = (idActivity: string, column: number, newValue: string) => {
		const newActivitites = activitiesOptions.map((activity) => {
			if (idActivity === activity.id)
				return {
					...activity,
					rows: activity.rows.map((rowData, index) => {
						if (index === column)
							return { key: rowData.key, value: newValue };
						else return rowData;
					}),
				};
			else return activity;
		});
		setActivitiesOptions([...newActivitites]);
	};

	return { usersData, activitiesOptions, matches, handleActivity, getIsTeam };
};
