import { Paper, Box, Grid } from '@mui/material';
import { Button } from '../../../components/main-button.component';
import { HeaderEdit } from '../../../components/header-edit.component';
import VerticalStepper from '../../../components/vertical-stepper-fixed.component';
import { INewInfoProject } from './interfaces/new-info-project.interface';
import PersonalIcon from '../../../assets/icons/personal.icon';
import { useViewAssignments } from './hooks/useViewAssignments';
import { AssignmentRow } from './item-assignments.component';
import { getDataProjectAdapted } from './adapters/activities-project.adapter';

interface IViewAssignments {
	currentProject: string;
	projectData: INewInfoProject | undefined;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	saveProjectData: (newDataProject: INewInfoProject) => void;
}

export const ViewAssignments = ({
	currentProject,
	projectData,
	setStep,
	saveProjectData,
}: IViewAssignments) => {
	const { usersData, activitiesOptions, matches, handleActivity, getIsTeam } =
		useViewAssignments({
			currentProject,
			projectData,
		});

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
				>
					<HeaderEdit
						section="Personal"
						title="2. Edita al personal responsable de la supervisión para cada actividad"
						alertText="Edita al personal que estará encargado de cada supervisión de actividad, así como los sustitutos"
						icon={<PersonalIcon size="23px" />}
					/>
					<Grid item xs={12}>
						<VerticalStepper
							gridKey="personal-project-table"
							rootStyle={{ overflowX: 'scroll', width: '100%' }}
							items={activitiesOptions}
							renderItem={(item, column) => (
								<AssignmentRow
									item={item}
									column={column}
									userList={usersData}
									onEdit={handleActivity}
								/>
							)}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Regresar"
							onClick={() => setStep(1)}
							variant="outlined"
							style={{
								width: '200px',
								color: 'var(--color-error-main)',
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							label="Guardar"
							onClick={() =>
								saveProjectData(
									getDataProjectAdapted(
										activitiesOptions,
										currentProject,
										getIsTeam
									)
								)
							}
							variant="solid"
							style={{
								width: '200px',
								backgroundColor: 'var(--color-highlight-blue)',
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
