import { Paper, Box, Grid } from '@mui/material';
import { HeaderEdit } from '../../components/header-edit.component';
import { useEditAssignments } from './hooks/useEditAssignments';
import PersonalIcon from '../../assets/icons/personal.icon';
import { ViewAssignments } from './components/view-assignments.component';
import { Button } from '../../components/main-button.component';

export const EditAssignments = () => {
	const { matches, navigate } = useEditAssignments();

	return (
		<Paper>
			<Box padding={3}>
				<Grid
					container
					spacing={3}
					justifyContent={matches ? 'center' : 'flex-end'}
				>
					<HeaderEdit
						section="Personal"
						title="1. Edita al personal responsable de la supervisión para cada actividad"
						alertText="Edita al personal que estará encargado de cada supervisión de actividad, así como los sustitutos"
						icon={<PersonalIcon size="23px" />}
					/>
					<Grid item xs={12} style={{ marginLeft: matches ? '0px' : '50px' }}>
						<ViewAssignments />
					</Grid>
					<Grid item>
						<Button
							label="Regresar"
							onClick={() => navigate(-1)}
							variant="outlined"
							style={{
								width: '200px',
								color: 'var(--color-error-main)',
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
