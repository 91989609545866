/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import { useApiRequest, IResponsePayload } from '../../../../../hooks/useApiRequest';
import { URLS } from '../../../../../constants/urls.constants';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../../../store/modules/steps';
import { setAlert } from '../../../../../store/modules/snackbar';
import { IReportsTable } from '../interfaces/report.interface';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { reportsAdapter } from '../adapters/reports-adapter-sh.adapter';
import { setLoading, stopLoading } from '../../../../../store/modules/loading';
import { ProfileProjectContext } from '../../../context/useProfileProjectContext';
import { useDownloadZip } from './useDownloadZip';

export const useViewFiles = () => {
	const dispatch = useDispatch();
	const { data: dataProject } = useContext(ProfileProjectContext);
	const { executeGetZip } = useDownloadZip();

	const {
		data: cards,
		setData: setCards,
		pagination: paginationC,
		rowsSelect: rowsSelectC,
		rowsPerPage: rowsPerPageC,
	} = useTableCustom<IReportsTable>();

	const {
		data: bulletins,
		setData: setBulletins,
		pagination: paginationB,
		rowsSelect: rowsSelectB,
		rowsPerPage: rowsPerPageB,
	} = useTableCustom<IReportsTable>();

	const {
		data: findings,
		setData: setFindings,
		pagination: paginationF,
		rowsSelect: rowsSelectF,
		rowsPerPage: rowsPerPageF,
	} = useTableCustom<IReportsTable>();

	const {
		data: activities,
		setData: setActivities,
		pagination: paginationA,
		rowsSelect: rowsSelectA,
		rowsPerPage: rowsPerPageA,
	} = useTableCustom<IReportsTable>();

	const {
		execute: executeCards,
		status: statusCards,
		value: valueCards,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.REPORTS.GET_REPORTS,
		method: 'GET',
	});

	const {
		execute: executeBulletins,
		status: statusBulletins,
		value: valueBulletins,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.REPORTS.GET_REPORTS,
		method: 'GET',
	});

	const {
		execute: executeFindings,
		status: statusFindings,
		value: valueFindings,
	} = useApiRequest<unknown, IResponsePayload<any>>({
		path: URLS.REPORTS.GET_REPORTS,
		method: 'GET',
	});

	const {
		execute: executeGetReport,
		status: statusGetReport,
		value: valueGetReport,
	} = useApiRequest<any, IResponsePayload<any>>({
		path: URLS.REPORTS.GET_URL_REPORT,
		method: 'POST',
	});

	const {
		execute: executeGetActivities,
		status: statusGetActivities,
		value: valueGetActivities,
	} = useApiRequest<unknown, IResponsePayload<IReportsTable>>({
		path: URLS.REPORTS.GET_REPORTS,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetReport === 'success') {
			dispatch(stopLoading());
			document.location = valueGetReport?.detail;
			dispatch(resetState());
		} else if (statusGetReport === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al descargar el documento',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetReport]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeCards(
			{},
			`?page=${paginationC}&recordsByPage=${rowsPerPageC}&project=${dataProject._id}&type=supervision-card`
		);
	}, [paginationC, rowsSelectC, rowsPerPageC]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeBulletins(
			{},
			`?page=${paginationB}&recordsByPage=${rowsPerPageB}&project=${dataProject._id}&type=supervision-bulletin`
		);
	}, [paginationB, rowsSelectB, rowsPerPageB]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeFindings(
			{},
			`?page=${paginationF}&recordsByPage=${rowsPerPageF}&project=${dataProject._id}&type=documento-hallazgos`
		);
	}, [paginationF, rowsSelectF, rowsPerPageF]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		executeGetActivities(
			{},
			`?page=${paginationA}&recordsByPage=${rowsPerPageA}&project=${dataProject._id}&type=documento-evidencias`
		);
	}, [paginationA, rowsSelectA, rowsPerPageA]);

	const downloadDocument = (fileNameKey: string, routine?: string, isZip?: boolean) => {
		dispatch(setLoading({ loading: true }));
		if (isZip && dataProject?._id && routine)
			executeGetZip({ project: dataProject._id, routine: routine });
		else if (!isZip) executeGetReport({ key: fileNameKey });
		else dispatch(stopLoading());
	};

	useEffect(() => {
		if (statusCards === 'success') {
			setCards({
				...cards,
				countRows: valueCards?.totalRecords || 0,
				rows: reportsAdapter(valueCards?.data || [], downloadDocument),
				rowsPerPage: rowsPerPageC,
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusCards === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error',
					severity: 'error',
				})
			);
		}
		dispatch(stopLoading());
	}, [statusCards]);

	useEffect(() => {
		if (statusBulletins === 'success') {
			setBulletins({
				...bulletins,
				countRows: valueBulletins?.totalRecords || 0,
				rows: reportsAdapter(valueBulletins?.data || [], downloadDocument, true),
				rowsPerPage: rowsPerPageB,
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusBulletins === 'error')
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error',
					severity: 'error',
				})
			);

		dispatch(stopLoading());
	}, [statusBulletins]);

	useEffect(() => {
		if (statusFindings === 'success') {
			setFindings({
				...findings,
				countRows: valueFindings?.totalRecords || 0,
				rows: reportsAdapter(valueFindings?.data || [], downloadDocument),
				rowsPerPage: rowsPerPageF,
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusFindings === 'error')
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error',
					severity: 'error',
				})
			);

		dispatch(stopLoading());
	}, [statusFindings]);

	useEffect(() => {
		if (statusGetActivities === 'success') {
			setActivities({
				...activities,
				countRows: valueGetActivities?.totalRecords || 0,
				rows: reportsAdapter(valueGetActivities?.data || [], downloadDocument),
				rowsPerPage: rowsPerPageB,
				sort: {
					col: '',
					order: 'ASC',
				},
			});
		} else if (statusGetActivities === 'error')
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error',
					severity: 'error',
				})
			);

		dispatch(stopLoading());
	}, [statusGetActivities]);

	return {
		setCards,
		setBulletins,
		setFindings,
		setActivities,
		cards,
		bulletins,
		findings,
		activities,
	};
};
