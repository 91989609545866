/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/modules/loading';
import { RootState } from '../store';
import { resetUser } from '../store/modules/user';
import { setAlert } from '../store/modules/snackbar';

export interface IResponsePayload<ParamsType> {
	recordsByPage: number;
	totalRecords: number;
	totalPages: number;
	data: ParamsType[];
	detail: ParamsType;
	page: number;
	message: string;
}
interface IRequestPayload {
	path: string;
	method: string;
	headers?: {
		[key: string]: string;
	};
}

export const useApiRequest = <ParamsType, ResponseType>(
	{ path, headers, method }: IRequestPayload,
	immediate = false
) => {
	const [status, setStatus] = useState<'pending' | 'success' | 'error'>('pending');
	const [statusRequest, setStatusRequest] = useState<number>();
	const [value, setValue] = useState<ResponseType | null>(null);
	const [totalRows, setTotalRows] = useState<number | null>(null);
	const [urlParams, setUrlParams] = useState<URLSearchParams | null>(null);
	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.users);

	const getTokenAuth = (): string => {
		const token = user.token;
		if (token) {
			return `Bearer ${token}`;
		}
		return '';
	};

	const execute = useCallback(async (params?: ParamsType, pathPag?: string) => {
		setStatus('pending');
		setValue(null);
		const abortController = new AbortController();
		setUrlParams(pathPag ? new URLSearchParams(pathPag) : null);
		try {
			const pathFlag = pathPag ? path + pathPag : path;
			const response = await axios({
				url: `${process.env.REACT_APP_API_URL}/${pathFlag}`,
				method,
				headers: {
					'ngrok-skip-browser-warning': true,
					'Content-Type': 'application/json; charset=utf-8',
					Accept: 'text/plain',
					Authorization: getTokenAuth(),
					...headers,
				},
				data: {
					...params,
				},
				signal: abortController.signal,
			});
			if (response.data.totalRegistros) {
				setTotalRows(Number(response.data.totalRegistros));
			}
			setStatusRequest(response.status);
			setValue(response.data);
			setStatus('success');
			return true;
		} catch (error: any) {
			if (
				error?.response?.statusText === 'Unauthorized' ||
				error?.response?.status === 401
			) {
				dispatch(
					setAlert({
						show: true,
						message: 'La sesión ha expirado',
						severity: 'warning',
					})
				);
				dispatch(resetUser());
			}
			if (error?.response?.data) {
				setValue(error?.response?.data);
			}
			setStatus('error');
			dispatch(setLoading({ loading: false }));
		}
		return () => abortController.abort();
	}, []);

	useEffect(() => {
		if (immediate) {
			execute();
		}
	}, [execute, immediate]);

	return {
		execute,
		status,
		value,
		totalRows,
		statusRequest,
		setStatus,
		urlParams,
		setValue,
	};
};
