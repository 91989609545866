/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '../../../constants/urls.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { stopLoading } from '../../../store/modules/loading';
import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import {
	getActivitiesAdapter,
	getActivityAdapter,
} from '../adapters/getActivities.adapter';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import { useGetPersonalByProject } from '../../../hooks/usePersonalByProject';
import { useApiRequest, IResponsePayload } from '../../../hooks/useApiRequest';
import { useEditAssignationActivity } from './useEditAssignationActivity';
import { KEYS_LABEL } from '../constants/keys.constants';

export const useViewAssignments = () => {
	const dispatch = useDispatch();
	const [activitiesOptions, setActivitiesOptions] = useState<IAdaptedValues[]>([]);
	const [activitiesRaw, setActivitiesRaw] = useState<IStepTwoModel[]>([]);
	const { _id } = useSelector((state: RootState) => state.projectBase);

	const { executeUpdate } = useEditAssignationActivity();
	const { usersData, getIsTeam, getTotalUsers } = useGetPersonalByProject({
		idProjectProp: _id,
		addTeams: true,
	});

	const {
		execute: getActivities,
		status: statusGetActivities,
		value: valueActivities,
	} = useApiRequest<any, IResponsePayload<IStepTwoModel>>({
		path: URLS.PROJECT.ACTIVITY,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGetActivities === 'success') {
			dispatch(stopLoading());
			setActivitiesOptions(getActivitiesAdapter(valueActivities?.data || []));
			setActivitiesRaw(valueActivities?.data || []);
		} else if (statusGetActivities === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al obtener la información',
					severity: 'error',
				})
			);
			dispatch(stopLoading());
		}
	}, [statusGetActivities]);

	useEffect(() => {
		getActivities(null, `?project=${_id}`);
		getTotalUsers();
	}, []);

	const handleActivity = async (
		idActivity: string,
		data: Record<string, string>
	): Promise<boolean> => {
		const activity = activitiesRaw.find((a) => a._id === idActivity);
		const isTeamExecutor = getIsTeam(data['Ejecutor']);
		const isTeamSupervisor = getIsTeam(data['Supervisor']);
		const isTeamSubstitute1 = getIsTeam(data['Sustituto 1']);
		const isTeamSubstitute2 = getIsTeam(data['Sustituto 2']);
		if (activity) {
			const response = await executeUpdate(
				getActivityAdapter(activity, {
					[KEYS_LABEL['Ejecutor'][0]]: isTeamExecutor ? null : data['Ejecutor'],
					[KEYS_LABEL['Ejecutor'][1]]: isTeamExecutor ? data['Ejecutor'] : null,
					[KEYS_LABEL['Supervisor'][0]]: isTeamSupervisor
						? null
						: data['Supervisor'],
					[KEYS_LABEL['Supervisor'][1]]: isTeamSupervisor
						? data['Supervisor']
						: null,
					[KEYS_LABEL['Sustituto 1'][0]]: isTeamSubstitute1
						? null
						: data['Sustituto 1'],
					[KEYS_LABEL['Sustituto 1'][1]]: isTeamSubstitute1
						? data['Sustituto 1']
						: null,
					[KEYS_LABEL['Sustituto 2'][0]]: isTeamSubstitute2
						? null
						: data['Sustituto 2'],
					[KEYS_LABEL['Sustituto 2'][1]]: isTeamSubstitute2
						? data['Sustituto 2']
						: null,
				})
			);
			return response;
		} else return false;
	};

	return { usersData, activitiesOptions, handleActivity };
};
