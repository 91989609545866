import { Grid, useMediaQuery } from '@mui/material';
import SelectComponent from '../../../components/select.component';
import { IOptionsMenu } from '../../../interfaces/select.interface';
import styles from './styles/item-assignment-styles.module.css';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';
import theme from '../../../config/theme';
import { useState } from 'react';

interface IItemAssignment {
	item: IAdaptedValues;
	column: number;
	userList: IOptionsMenu[];
	onEdit: (idActivity: string, data: Record<string, string>) => Promise<boolean>;
}

export const AssignmentRow = ({ item, column, userList, onEdit }: IItemAssignment) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [data, setData] = useState<{ [key: string]: string }>({
		Ejecutor: item.rows[0].value,
		Supervisor: item.rows[1].value,
		'Sustituto 1': item.rows[2].value,
		'Sustituto 2': item.rows[3].value,
	});

	const onChangeValue = async (newValue: string, key: string) => {
		const valueEdit = await onEdit(item.id, { ...data, [key]: newValue });
		if (valueEdit) {
			setData({
				...data,
				[key]: newValue || '',
			});
		}
	};

	return (
		<Grid item xs={matches ? 10 : 11} className={styles.renderItem}>
			<Grid item xs="auto">
				<span style={{ fontWeight: 'bold' }}>{item.description}</span>
			</Grid>
			{item.rows.map((el, row) => (
				<Grid item key={`supervision-table-${column}-${row}`}>
					<SelectComponent
						id={`supervision-table-${column}-${row}`}
						formSx={{ width: '250px' }}
						placeholder={'Selecciona un valor'}
						onChange={(e) =>
							onChangeValue(e?.target?.value?.toString() || '', el.key)
						}
						RenderTitle={() => {
							if (column > 0) return null;
							return <p>{el.key}</p>;
						}}
						value={userList.length > 0 ? data[el.key] : ''}
						optionsMenu={userList}
						disabled={el.isDisabled}
						filterOptions
					/>
				</Grid>
			))}
		</Grid>
	);
};
