/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import { setValuePP } from '../../../../../store/modules/profile-project';
import TableCustom from '../../../../../components/table-custom.component';
import { columnsReports } from '../utils/columns.utils';
import { useViewFiles } from '../hooks/useViewFiles';

export const ViewReports = () => {
	const dispatch = useDispatch();
	const {
		setCards,
		setBulletins,
		setFindings,
		setActivities,
		activities,
		cards,
		bulletins,
		findings,
	} = useViewFiles();

	return (
		<>
			<div
				onClick={() => dispatch(setValuePP({ stepReporteo: 0 }))}
				style={{
					display: 'flex',
					gap: '5px',
					padding: '10px',
					cursor: 'pointer',
					width: 'max-content',
					color: 'var(--color-highlight-blue)',
				}}
			>
				<ArrowBack />
				<span>Regresar</span>
			</div>
			<Grid
				container
				padding="10px"
				columnSpacing={8}
				rowSpacing={3}
				alignItems="center"
				justifyContent="flex-end"
			>
				<Grid item xs={12}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>
						Cédula de supervisión
					</span>
				</Grid>
				<Grid item xs={12}>
					<TableCustom
						columns={columnsReports}
						data={cards}
						setData={setCards}
						showPagination
						showLimitPerPage
						hideHeader
					/>
				</Grid>
				<Grid item xs={12}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>
						Boletín de supervisión
					</span>
				</Grid>
				<Grid item xs={12}>
					<TableCustom
						columns={columnsReports}
						data={bulletins}
						setData={setBulletins}
						showPagination
						showLimitPerPage
						hideHeader
					/>
				</Grid>
				<Grid item xs={12}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>
						Reporte de hallazgos
					</span>
				</Grid>
				<Grid item xs={12}>
					<TableCustom
						columns={columnsReports}
						data={findings}
						setData={setFindings}
						showPagination
						showLimitPerPage
						hideHeader
					/>
				</Grid>
				<Grid item xs={12}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>
						Reporte de actividades
					</span>
				</Grid>
				<Grid item xs={12}>
					<TableCustom
						columns={columnsReports}
						data={activities}
						setData={setActivities}
						showPagination
						showLimitPerPage
						hideHeader
					/>
				</Grid>
			</Grid>
		</>
	);
};
