import { AssignmentRow } from './assignmentRow.component';
import { useViewAssignments } from '../hooks/useViewAssignments';
import VerticalStepper from '../../../components/vertical-stepper-fixed.component';

export const ViewAssignments = () => {
	const { usersData, activitiesOptions, handleActivity } = useViewAssignments();

	return (
		<VerticalStepper
			gridKey="personal-project-table"
			rootStyle={{
				overflowX: 'scroll',
				width: '100%',
			}}
			items={activitiesOptions}
			renderItem={(item, column) => (
				<AssignmentRow
					item={item}
					column={column}
					userList={usersData}
					onEdit={handleActivity}
				/>
			)}
		/>
	);
};
