/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import CreationPage from '../../../../components/creation-page.component';
import { estadoCreacion } from '../../../../constants/estado-creacion';
import { ICreationPage } from '../../../../interfaces/creation-page-interfaces';
import { TReactDispatch } from '../../../../interfaces/utils';
import { DefaultTabItems } from '../../constants/data.constants';
import StepTwo from './step-two.component';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, stopLoading } from '../../../../store/modules/loading';
import usePersonal from '../../hooks/usePersonal';
import { IStepTwoModel } from '../../../../interfaces/stepTwo.interface';
import { patchActivitiesAdapter } from '../../../../adapters/step-two.adapter';
import { uniqueId } from '../../../../utils/uniqueId';
import useUpdateProject from '../../../../hooks/useUpdateProject';
import { RootState } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../../../store/modules/snackbar';
import { isEmpty } from '../../../../utils/validators';
import { useGetPersonalByProject } from '../../../../hooks/usePersonalByProject';

interface IProps {
	currentTab: number;
	setCurrentTab?: TReactDispatch<number>;
	isEdit?: boolean;
}
const AsignacionSuperviciones = (props: IProps) => {
	const dispatch = useDispatch<any>();
	const navigate = useNavigate();
	const { currentTab, isEdit = false } = props;
	const { step: projectStep, _id: _projectID } = useSelector(
		(state: RootState) => state.projectBase
	);
	const [isContinue, setIsContinue] = useState(false);
	const {
		getActivities,
		statusGetActivities,
		statusUpdateActivities,
		updateActivities,
		valueActivities,
		updateAssignments,
		statusUpdateAssignments,
	} = usePersonal();
	const [activityData, setActivityData] = useState<IStepTwoModel[]>([]);
	const [activityBase, setActivityBase] = useState<IStepTwoModel[]>([]);
	const { handleUpdateProject } = useUpdateProject();

	const { usersData, getIsTeam, getTotalUsers } = useGetPersonalByProject({
		addTeams: true,
	});

	useEffect(() => {
		if (isEmpty(_projectID)) navigate(-1);
		getActivities(null, `?project=${_projectID}`);
		getTotalUsers();
	}, []);

	useEffect(() => {
		if (!valueActivities) return;
		const _data: IStepTwoModel[] = valueActivities.data.map((i) => ({
			attributes: isEdit
				? ['Ejecutor', 'Supervisor', 'Sustituto 1', 'Sustituto 2']
				: ['Supervisor', 'Sustituto 1', 'Sustituto 2'],
			columns: isEdit
				? [
						i?.executor || i?.executorTeam || '',
						i?.supervisor || i?.supervisorTeam || '',
						i?.substitute1 || i?.substitute1Team || '',
						i?.substitute2 || i?.substitute2Team || '',
				  ]
				: [
						i?.supervisor || i?.supervisorTeam || '',
						i?.substitute1 || i?.substitute1Team || '',
						i?.substitute2 || i?.substitute2Team || '',
				  ],
			id: uniqueId(),
			_id: i._id,
			isComplete: isEdit
				? (!isEmpty(i.executor) || !isEmpty(i.executorTeam)) &&
				  (!isEmpty(i.supervisor) || !isEmpty(i.supervisorTeam)) &&
				  (!isEmpty(i.substitute1) || !isEmpty(i.substitute1Team)) &&
				  (!isEmpty(i.substitute2) || !isEmpty(i.substitute2Team))
				: (i.supervisor || i.supervisorTeam) &&
				  (i.substitute1 || i.substitute1Team) &&
				  (i.substitute2 || i.substitute2Team)
				? true
				: false,
			mainAttribute: 'Supervisor',
			name: i.columns[0],
		}));
		setActivityBase(valueActivities.data);
		setActivityData(_data);
	}, [statusGetActivities]);

	useEffect(() => {
		if (statusUpdateActivities === 'success') {
			dispatch(stopLoading());
			if (isEdit && isContinue) {
				navigate(-1);
			} else if (currentTab === 2 && isContinue) {
				handleUpdateProject({ step: '5.0.0' }, _projectID || '');
			}
		} else if (statusUpdateActivities === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar los responsables',
					severity: 'error',
				})
			);
		}
	}, [statusUpdateActivities]);

	useEffect(() => {
		if (statusUpdateAssignments === 'success') {
			dispatch(stopLoading());
			navigate(-1);
		} else if (statusUpdateAssignments === 'error') {
			dispatch(stopLoading());
			dispatch(
				setAlert({
					show: true,
					message: 'Ocurrió un error al actualizar los responsables',
					severity: 'error',
				})
			);
		}
	}, [statusUpdateAssignments]);

	useEffect(() => {
		if (!projectStep) return;
		if (projectStep === '5.0.0') navigate('/nuevo-proyecto/cronograma');
	}, [projectStep]);

	const headerConfig = {
		label: 'Estado de creación',
		stepper: {
			activeStep: 3,
			items: estadoCreacion,
			completed: { 0: true, 1: true },
		},
		tabs: {
			items: DefaultTabItems,
			value: currentTab,
			onTabChange: () => null,
		},
		show: !isEdit,
	};

	const AsignacionSupervicionesPage: ICreationPage = {
		header: { ...headerConfig },
		stepTwo: {
			title: '1. Selecciona al personal responsable de la supervisión para cada actividad',
			show: true,
			hideHeader: isEdit,
			alertLabel:
				'Selecciona al personal que estará encargado de cada una de las relaciones, así como a los sustitutos',
			Children: (
				<StepTwo
					userList={usersData}
					onContinue={async (data) => {
						if (isEdit) {
							navigate(-1);
						} else {
							setIsContinue(true);
							const adapted = patchActivitiesAdapter(
								data,
								activityBase,
								isEdit,
								getIsTeam,
								_projectID
							);
							dispatch(setLoading({ loading: true }));
							updateActivities(adapted);
						}
					}}
					onSave={(data) => {
						setIsContinue(false);
						const adapted = patchActivitiesAdapter(
							data,
							activityBase,
							isEdit,
							getIsTeam,
							_projectID
						);
						dispatch(setLoading({ loading: true }));
						if (isEdit) updateAssignments(adapted);
						else updateActivities(adapted);
					}}
					url=""
					data={activityData}
					setData={setActivityData}
					isDone={false}
				/>
			),
			showExitButon: false,
		},
	};
	return (
		<div>
			<CreationPage {...AsignacionSupervicionesPage} />
		</div>
	);
};

export default AsignacionSuperviciones;
