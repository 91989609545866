// components
import Devices from '../../pages/devices';
import Home from '../../pages/home';
import Criterios from '../../pages/criterios/Index';
import Personal from '../../pages/personal/Index';
import DimensionesGeograficas from '../../pages/dimensiones-geograficas/Index';
import { UsersPage } from '../../pages/users';
import { NewUserComponent } from '../../pages/users/components/new-user.component';
import { UserProfile } from '../../pages/user-profile';
import Actividades from '../../pages/actividades/Index';
import Cronograma from '../../pages/cronograma/Index';

import { ProjectProfile } from '../../pages/profile-project';
import { SupervisionDetail } from '../../pages/supervision-detail-project';
import { SupervisionDetailTasks } from '../../pages/supervision-detail-project/supervisionDetailTasks';
import { SupervisionComponent } from '../../pages/supervision-project';
import { SupervisionAction } from '../../pages/supervision-action';
import { ViewProjects } from '../../pages/view-projects';
import { SupervisionExtraordinary } from '../../pages/supervision-extraordinary';
import { NextTasks } from '../../pages/next-tasks';
import { ExtraordinaryIncidence } from '../../pages/extraordinary-incidence';
import { SupervisionHistory } from '../../pages/supervision-history';
import { CreateSupervisionExtraordinary } from '../../pages/create-supervision-extraordinary';

import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as UsersIcon } from '../../assets/svg/users.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/svg/projects.svg';
import { ReactComponent as DevicesIcon } from '../../assets/svg/devices.svg';
import { SvgIcon } from '@mui/material';
import { NewProject } from '../../pages/new-project';
import ProyectSuccesful from '../../pages/proyect-created/Index';
import Suspension from '../../pages/suspension/index';
import SuspensionComponent from '../../pages/suspension-detail-project/index';
import DocumentUploadSuccesfull from '../../pages/save-docs/document-upload-succesfull';
import { SuspendRoutine } from '../../pages/suspend-routine';
import { SummaryRoutine } from '../../pages/supervision-project/components/summary-routine/summary-routine.component';
import { IMenuList, styleIcon, styleIconContainer } from '../utils/resources.utils';
import { EditActivities } from '../../pages/edit-activities/edit-activities.component';
import { EditGeneralIndicators } from '../../pages/edit-indicators/components/edit-general-indicators/edit-general-indicators.component';
import { EditSpecificIndicators } from '../../pages/edit-indicators/components/edit-specific-indicators/edit-specific-indicators.component';
import { EditFindings } from '../../pages/edit-indicators/components/edit-findings/edit-findings.component';
import { EditFunctionalLvl1 } from '../../pages/edit-functionals/components/funtionals-lvl1/editFunctionalLvl1.component';
import { EditFunctionalLvl2 } from '../../pages/edit-functionals/components/functionals-lvl2/editFunctionalLvl2.component';
import { EditNonWorkingDays } from '../../pages/edit-schedule/components/non-working-days/non-working-days.component';
import { EditScheduleByIndicator } from '../../pages/edit-schedule/components/schedule-by-indicator/schedule-by-indicator.component';
import { EditScheduleActivity } from '../../pages/edit-schedule/components/schedule-activity/schedule-activity.component';
import { EditScheduleByLevel } from '../../pages/edit-schedule/components/schedule-by-level/schedule-by-level.component';
import { EditRelationsUA } from '../../pages/edit-functionals/components/functionals-relations-UA/edit-relations-UA.component';
import { EditRelationsUI } from '../../pages/edit-functionals/components/functionals-relations-UI/edit-relations-UI.component';
import { CreatePersonal } from '../../pages/personal/components/create-personal/create-personal.component';
import { CreateTeams } from '../../pages/personal/components/create-teams/create-teams.component';
import { EditAssignments } from '../../pages/edit-assigments/edit-assigments.component';
import NotificationList from '../../pages/notifications';
import { UserManagment } from '../../pages/user-project-management/user-management.component';
import { ViewBaseTasks } from '../../pages/view-base-task';
import { SummaryTasks } from '../../pages/summary-tasks';
import { AutomaticCharge } from '../../pages/automatic-charge/automatic-charge.component';

export const RouteAdminList: IMenuList[] = [
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Inicio',
		link: '/inicio',
		Component: Home,
	},
	{
		icon: (
			<div style={styleIconContainer}>
				<HomeIcon style={styleIcon} />
			</div>
		),
		title: 'Mi Perfil',
		link: '/mi-perfil',
		Component: UserProfile,
		hideMenu: true,
	},
	{
		icon: (
			<SvgIcon>
				<ProjectsIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Nuevo Proyecto',
		link: '/nuevo-proyecto',
		Component: NewProject,
		hideMenu: true,
		submenu: [
			{
				title: 'Dimensiones geográficas',
				link: '/dimensiones-geograficas',
				Component: DimensionesGeograficas,
			},
			{
				title: 'Actividades',
				link: '/actividades',
				Component: Actividades,
			},
			{
				title: 'Criterios',
				link: '/criterios',
				Component: Criterios,
			},
			{
				title: 'Personal',
				link: '/personal',
				Component: Personal,
			},
			{
				title: 'Cronograma',
				link: '/cronograma',
				Component: Cronograma,
			},
			{
				title: 'Proyecto creado',
				link: '/proyecto-creado',
				Component: ProyectSuccesful,
				hideMenu: true,
			},
		],
	},
	{
		title: 'Creación automática',
		link: '/creacion-automatica',
		Component: AutomaticCharge,
		hideMenu: true,
	},
	{
		icon: (
			<div style={styleIconContainer}>
				<ProjectsIcon style={styleIcon} />
			</div>
		),
		title: 'Proyectos',
		link: '/proyectos',
		Component: ViewProjects,
	},
	{
		icon: (
			<SvgIcon>
				<ProjectsIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Perfil de proyecto',
		link: '/perfil-proyecto',
		hideMenu: true,
		Component: ProjectProfile,
		submenu: [
			{
				title: 'Edición de la supervisión',
				link: '/editar-datos-generales',
				Component: NewProject,
				hideMenu: true,
			},
			{
				title: 'Detalle de la supervisión',
				link: '/ver-supervision',
				Component: SummaryRoutine,
				hideMenu: true,
			},
			{
				title: 'Supervision',
				link: '/supervision-confirmar',
				Component: SupervisionAction,
				hideMenu: true,
			},
			{
				title: 'Supervision',
				link: '/supervision',
				Component: SupervisionComponent,
				hideMenu: true,
			},
			{
				title: 'Incidencia extraordinaria',
				link: '/incidencia-extraordinaria',
				Component: ExtraordinaryIncidence,
				hideMenu: true,
			},
			{
				title: 'Historial de supervisión',
				link: '/historial-supervision',
				Component: SupervisionHistory,
				hideMenu: true,
			},
			{
				icon: (
					<SvgIcon>
						<ProjectsIcon style={styleIcon} />
					</SvgIcon>
				),
				title: 'Próximas tareas',
				link: '/proximas-tareas',
				hideMenu: true,
				Component: NextTasks,
				submenu: [
					{
						title: 'Detalle de la supervisión',
						link: '/ver-supervision',
						Component: SupervisionDetailTasks,
						hideMenu: true,
						submenu: [
							{
								title: 'Suspender supervisión',
								link: '/suspender-supervision',
								Component: SuspendRoutine,
								hideMenu: true,
							},
						],
					},
				],
			},
			{
				icon: (
					<SvgIcon>
						<ProjectsIcon style={styleIcon} />
					</SvgIcon>
				),
				title: 'Supervisión extraordinaria',
				link: '/supervision-extraordinaria',
				hideMenu: true,
				Component: SupervisionExtraordinary,
				submenu: [
					{
						title: 'Detalle de la supervisión',
						link: '/ver-supervision',
						Component: SupervisionDetail,
						hideMenu: true,
					},
					{
						title: 'Crear supervisión extraordinaria',
						link: '/crear-supervision-extraordinaria',
						hideMenu: true,
						Component: CreateSupervisionExtraordinary,
					},
				],
			},
			{
				icon: (
					<SvgIcon>
						<ProjectsIcon style={styleIcon} />
					</SvgIcon>
				),
				title: 'Suspensiones',
				link: '/suspensiones',
				hideMenu: true,
				Component: Suspension,
				submenu: [
					{
						title: 'Detalle de la suspensión',
						link: '/ver-suspension',
						Component: SuspensionComponent,
						hideMenu: true,
					},
				],
			},
			{
				title: 'Cronograma Unidad',
				link: '/cronograma-unidad',
				Component: EditScheduleByLevel,
			},
			{
				title: 'Cronograma Servicio',
				link: '/cronograma-actividad',
				Component: EditScheduleActivity,
			},
			{
				title: 'Cronograma Indicador',
				link: '/cronograma-indicador',
				Component: EditScheduleByIndicator,
			},
			{
				title: 'Cronograma Laborales',
				link: '/cronograma-editar-dias-laborales',
				Component: EditNonWorkingDays,
			},
			{
				title: 'Dimensiones geográficas nivel uno',
				link: '/dimensiones-nivel-uno',
				Component: EditFunctionalLvl1,
			},
			{
				title: 'Dimensiones geográficas nivel dos',
				link: '/dimensiones-nivel-dos',
				Component: EditFunctionalLvl2,
			},
			{
				title: 'Relaciones espacios-actividades',
				link: '/relaciones-espacios-actividades',
				Component: EditRelationsUA,
			},
			{
				title: 'Relaciones espacios-indicadores',
				link: '/relaciones-espacios-indicadores',
				Component: EditRelationsUI,
			},
			{
				title: 'Modificar asignaciones',
				link: '/edicion-asignaciones',
				Component: EditAssignments,
			},
			{
				title: 'Modificar equipos',
				link: '/edicion-equipos',
				Component: CreateTeams,
			},
			{
				title: 'Edición roles',
				link: '/edicion-roles',
				Component: CreatePersonal,
				submenu: [
					{
						title: 'Desactivar usuario',
						link: '/desactivar-usuario',
						Component: UserManagment,
						hideMenu: true,
					},
				],
			},
			{
				title: 'Edición actividades',
				link: '/edicion-actividades',
				Component: EditActivities,
			},
			{
				title: 'Edición criterios generales',
				link: '/edicion-criterios-generales',
				Component: EditGeneralIndicators,
			},
			{
				title: 'Edición criterios específicos',
				link: '/edicion-criterios-especificos',
				Component: EditSpecificIndicators,
			},
			{
				title: 'Edición Hallazgos',
				link: '/edicion-hallazgos',
				Component: EditFindings,
			},
			{
				title: 'Resumen de tareas',
				link: '/resumen-tareas',
				Component: SummaryTasks,
				submenu: [
					{
						title: 'Ver tareas base',
						link: '/ver-tareas-base',
						Component: ViewBaseTasks,
					},
				],
			},
		],
	},
	{
		title: 'Supervisión extraordinaria',
		link: '/suspender-supervision',
		hideMenu: true,
		Component: SuspendRoutine,
	},
	{
		icon: (
			<div style={styleIconContainer}>
				<UsersIcon style={styleIcon} />
			</div>
		),
		title: 'Usuarios',
		link: '/usuarios',
		Component: UsersPage,
	},
	{
		title: 'Nuevo usuario',
		link: '/usuarios/crear-usuario',
		Component: NewUserComponent,
		hideMenu: true,
	},
	{
		title: 'Perfil de usuario',
		link: '/usuarios/perfil-usuario',
		Component: UserProfile,
		hideMenu: true,
		submenu: [],
	},
	{
		title: 'Desactivar usuario',
		link: '/usuarios/baja-usuario',
		Component: UserManagment,
		hideMenu: true,
	},
	{
		title: 'Mensaje',
		link: '/proyecto/mensaje-proyecto',
		Component: DocumentUploadSuccesfull,
		hideMenu: true,
	},
	{
		icon: (
			<SvgIcon>
				<DevicesIcon style={styleIcon} />
			</SvgIcon>
		),
		title: 'Notificaciones',
		link: '/notificaciones',
		Component: NotificationList,
		hideMenu: true,
	},
	{
		icon: (
			<div style={styleIconContainer}>
				<DevicesIcon style={styleIcon} />
			</div>
		),
		title: 'Dispositivos',
		link: '/dispositivos',
		Component: Devices,
	},
];
