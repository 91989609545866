import Grid from '@mui/material/Grid';
import StepTwoFixed from '../../../../components/step-two-fixed';
import renderItem from '../../styles/render-item-styles.module.css';
import SelectComponent from '../../../../components/select.component';
import { IOptionsMenu } from '../../../../interfaces/select.interface';
import { IStepProps, IRenderItemProps } from '../../../../interfaces/stepTwo.interface';

interface IItem {
	itemProps: IRenderItemProps;
	userList: IOptionsMenu[];
}

const ColumnItem = (props: IItem) => {
	const { item, column, handleTable } = props.itemProps;
	const { userList } = props;
	return (
		<Grid item className={renderItem.renderItem}>
			{item.columns.map((el, row) => (
				<Grid item xs={10} key={`superv-table-${column}-${row}`}>
					<SelectComponent
						id={`superv-table-${column}-${row}`}
						formSx={{ width: '100%' }}
						placeholder={'Selecciona un valor'}
						onChange={(e) => {
							handleTable(column, row, e.target.value);
						}}
						RenderTitle={() => {
							if (column > 0) return null;
							return <p>{item.attributes[row]}</p>;
						}}
						value={userList.length > 0 ? el : ''}
						optionsMenu={userList}
						filterOptions
					/>
				</Grid>
			))}
		</Grid>
	);
};

interface IStepTwoProps extends IStepProps {
	userList: IOptionsMenu[];
}

const StepTwoComponent = (props: IStepTwoProps) => {
	const { userList } = props;

	return (
		<StepTwoFixed
			{...props}
			hiddeCsvButton
			hiddeAddButton
			hiddeVerticalNumbers
			hiddeDeleteItemsButtons
			id="btn-continue-asignaciones"
			renderItem={(eleProps) => {
				return <ColumnItem itemProps={eleProps} userList={userList} />;
			}}
		/>
	);
};

export default StepTwoComponent;
