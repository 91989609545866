import { IStepTwoModel } from '../../../interfaces/stepTwo.interface';
import { IAdaptedValues } from '../../../interfaces/edit-adapted-values';

export const getActivitiesAdapter = (activities: IStepTwoModel[]) => {
	const adaptedData = activities.map((activity) => {
		return {
			id: activity?._id || '',
			mainAttribute: 'Actividad',
			description: activity?.columns[0] || '',
			rows: [
				{
					key: 'Ejecutor',
					value: activity.executor || activity.executorTeam || '',
				},
				{
					key: 'Supervisor',
					value: activity.supervisor || activity.supervisorTeam || '',
				},
				{
					key: 'Sustituto 1',
					value: activity.substitute1 || activity.substitute1Team || '',
				},
				{
					key: 'Sustituto 2',
					value: activity.substitute2 || activity.substitute2Team || '',
				},
			],
			frequency: '',
			isComplete: true,
		};
	});

	return adaptedData as unknown as IAdaptedValues[];
};

export const getActivityAdapter = (
	activity: IStepTwoModel,
	data: Record<string, string | null>
) => {
	return {
		...activity,
		...data,
	};
};
